import React, { useEffect, useState } from "react";
import { Spinner, Center } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Input,
} from "@chakra-ui/react";

import { getCommonGames, addGame } from "../../api/index";
import GameAchievementModal from "../../components/Achievement/GameAchievementModal";
// import AchievementLabel from "../../components/Recipe/AchievementLabel";
// import RecipeModal from "../../components/Recipe/RecipeModal";

const CommonGamesPage = () => {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);
  const [ size, setSize ] = useState(40)

  const getGamesData = async () => {
    setLoading(true);
    const response = await getCommonGames();
    if (response && response.games) {
      setGames(response.games);
    }
    setLoading(false);
  };

  useEffect(() => {
    getGamesData();
  }, []);

  const onSelectRow = (game) => {
    setSelectedGame(game);
  };

  return (
    <>
      {selectedGame && (
        <GameAchievementModal
          isOpen={!!selectedGame}
          onClose={() => setSelectedGame(null)}
          gameAppId={`${selectedGame.appid}`}
        />
      )}
      {games && (
        <div className="user-container" style={{ padding: "80px 64px" }}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>sn</Th>
                  <Th>appid</Th>
                  <Th>name</Th>
                  <Th>total users</Th>
                  <Th>total playtime</Th>
                  <Th>avg playtime</Th>
                </Tr>
              </Thead>
              <Tbody>
                {games.slice(0, size).map((game, index) => {
                  return (
                    <Tr
                      key={index}
                      onClick={() => onSelectRow(game)}
                      cursor="pointer"
                      _hover={{ backgroundColor: "lightgray" }}
                    >
                      {" "}
                      <Td>{index}</Td>
                      <Td>{game.appid}</Td>
                      <Td>{game.name}</Td>
                      <Td>{game.totalUsers}</Td>
                      <Td>{game.totalPlayTime}</Td>
                      <Td>{game.averagePlayTime}</Td>
                      <Td>
                        <Button colorScheme="green">Add Achievements</Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <Button margin= '20px' onClick={() => setSize(size + 40)}>Load More...</Button>
          </TableContainer>
        </div>
      )}
      {loading && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            margin="auto"
            alignContent={"center"}
          />
        </Center>
      )}
    </>
  );
};

export default CommonGamesPage;
