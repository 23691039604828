import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Progress,
  Icon,
  useDisclosure,
  Input,
  Button,
  Select,
  Checkbox,
  Stack,
  Form
} from "@chakra-ui/react";
import { MdClose, MdDownloadDone } from "react-icons/md";
import { uploadAchievement } from "../../../api";

UploadProgress.propTypes = {

};

function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

const convertToCsv = (array) => {
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(array[0]);
  const csvContent = [
    header.join(","), // header row first
    ...array.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");
  const dateString = new Date().toISOString();
  const fileName = `${dateString}-achievemeentns.csv`;
  downloadBlob(csvContent, fileName, "text/csv;charset=utf-8;");
};

function UploadProgress( props) {
  const {appid, gameName, achievements, onClose} = props
    const [uploadData, setUploadData] = React.useState({
      toUploadAchievements: [],
      uploading: false,
      uploadedAchievements: [],
      errorAchievements: [],
    });

    useEffect(() => {
      if(achievements.length) {
        uploadAchievements(achievements);
      }
    }, [achievements]);

  const uploadAchievements = async (achievementToAdd) => {
    achievementToAdd = achievementToAdd.map((achievement) => {
      return {
        apiname: achievement.name,
        name: achievement.displayName,
        description: achievement.description,
        appid,
        rarity: achievement.rarity,
        gameName: gameName,
        platform: "Steam",
      };
    });
    const errorArray = [];
    const uploadedArray = [];
    setUploadData({
      ...uploadData,
      toUploadAchievements: achievementToAdd,
      uploading: true,
    });

    for (let index = 0; index < achievementToAdd.length; index++) {
      const achievement = achievementToAdd[index];
      if (achievement.rarity) {
        achievement.rarity = parseFloat(achievement.rarity);
      }
      if (achievement.apiname) {
        achievement.apiname = achievement.apiname.trim();
      }
      if (achievement.name) {
        achievement.name = achievement.name.trim();
      }
      if (achievement.gameName) {
        achievement.gameName = achievement.gameName.trim();
      }
      try {
        await uploadAchievement({ achievement });
        uploadedArray.push(achievement);
        setUploadData({
          ...uploadData,
          uploadedAchievements: uploadedArray,
          toUploadAchievements: achievementToAdd,
          uploading: true,
        });
      } catch (error) {
        const errorMessage = get(error, "response.data.message");
        errorArray.push({ ...achievement, error: errorMessage });
        setUploadData({
          ...uploadData,
          errorAchievements: errorArray,
          uploadedAchievements: uploadedArray,
          toUploadAchievements: achievementToAdd,
          uploading: true,
        });
      }
    }
    if (errorArray.length > 0) {
      console.log(errorArray);
      // eslint-disable-next-line no-restricted-globals
      const downloadCsv = confirm(
        `${errorArray.length} achievements failed to upload. do you want to download the failed achievements?`
      );
      if (downloadCsv) convertToCsv(errorArray);
    } else {
      setTimeout(() => {
        // setUploadData({ ...uploadData, uploading: false });
        alert("All achievements uploaded successfully");
        onClose()
      }, 1000);
    }
  };
  const uploadCompletePercentage =
    uploadData.uploadedAchievements.length &&
    uploadData.toUploadAchievements.length
      ? (uploadData.uploadedAchievements.length /
          uploadData.toUploadAchievements.length) *
        100
      : 0;
  return (
    <>
      <ModalHeader>Upload Progress:</ModalHeader>

      <Progress value={parseInt(uploadCompletePercentage)} />
      <div>
        <div>Uploaded achievements:</div>
        {uploadData.uploadedAchievements.map((achi, index) => {
          return (
            <div key={index} className="uploaded-achi">
              {index} {achi.name}[achi.apiname]{" "}
              <Icon as={MdDownloadDone} color="green" />
            </div>
          );
        })}

        <div>Failed uploads:</div>
        {uploadData.errorAchievements.map((achi, index) => {
          return (
            <div key={index} className="error-achi">
              {index} {achi.name}[{achi.apiname}]
              <Icon as={MdClose} color="red.500" />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default UploadProgress;
