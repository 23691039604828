import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from "react-select";
import get from "lodash/get";


import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Select,
  Stack,
  Checkbox
} from "@chakra-ui/react";

import { updateRecipe, deleteRecipe } from "../../../api";
import { getGameAchievements, getGlobalAchievements } from "../../../api";
RecipeModal.propTypes = {

};

function RecipeModal(props) {
  const { isOpen, flexes } = props;
   const [achievements, setAchievements] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [appid, setAppid] = useState(null)
  const [gameData, setGameData] = useState(
    null
  );
  const [currentRecipe, setCurrentRecipe] = useState(props.recipe);
  const [recipeChanged, setRecipeChanged] = useState(false)
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    if(loading)return
    props.onClose();
  }



  const onUpdate = async () => {
    setLoading(true)
    try {
      const selectedAchievements = checkedItems.filter((item) => item.checked).map((item) => item.data)
      const updatedRecipe  = {
        ...currentRecipe,
        // achievements: selectedAchievements.map((achievement) => achievement.vid),
        achievementKeys: selectedAchievements.map(
          (achievement) => achievement.unique_key
        ),
      }
      const recipeRarity = getAchievementRarity(selectedAchievements)
      if(recipeRarity) {
        updatedRecipe.rarity = recipeRarity
      }
      delete updatedRecipe.achievements
      await updateRecipe({recipe: updatedRecipe})
      window.location.reload();
    } catch (error) {
      alert(`something went wrong. ${JSON.stringify(error)}`)
      console.error('error while updating recipe', error)
      setLoading(false)
    }
    setLoading(false)
  }

  const onDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmResponse = confirm(`are you sure you want to delete this recipe(id: ${currentRecipe.id}?`)
    if (confirmResponse) {
      setLoading(true);
      try {
        await deleteRecipe(currentRecipe.id);
        window.location.reload();
      } catch (error) {
        alert(`something went wrong. ${JSON.stringify(error)}`);
        console.error("error while deleteing recipe", error);
        setLoading(false);
      }
      setLoading(false);
    }
  }

  const onFlexChange =  (event) => {
    setCurrentRecipe({
      ...currentRecipe,
      flexUniqueId: event.target.value
    })
    setRecipeChanged(true);
  }

  const getAchievementRarity = (achievements) => {
    let recipeRarity = null
    achievements.forEach(({rarity}) => {
      if (rarity) {
        recipeRarity = recipeRarity
        ? recipeRarity * (rarity / 100)
        : (rarity / 100);
      }
    });
    return recipeRarity;
  }

  // const onAchievementChange = () => {
  //   // setSelectedAchievements(achievements);
  //   const selectedAchievements = checkedItems.filter((item) => item.checked).map((item) => item.data)
  //   const updatedRecipe  = {
  //     ...currentRecipe,
  //     // achievements: selectedAchievements.map((achievement) => achievement.vid),
  //     achievementKeys: selectedAchievements.map(
  //       (achievement) => achievement.data.unique_key
  //     ),
  //   }
  //   const recipeRarity = getAchievementRarity(selectedAchievements)
  //   if(recipeRarity) {
  //     updatedRecipe.rarity = recipeRarity
  //   }
  //   setCurrentRecipe(updatedRecipe);
  //   console.log('updatedRecipe', updatedRecipe)
  //   setRecipeChanged(true);
  // }

  const onAppidChange = (appid) => {
    setAppid(appid)
  }

  const getGameAchievementsByAppid = () => {
    getGameData(appid)
  }


  const getGameData = async () => {
    setLoading(true);
    try {
      const globalAchievementResponse = await getGlobalAchievements(appid)
      const rarities = globalAchievementResponse.data.achievements.reduce((acc, achi) => {
        acc[achi.name] = achi.percent;
        return acc
      }, {})
      const response = await getGameAchievements(appid);
      setGameData(response.data);
      // const achievementArrayLength = get(
      //   response.data,
      //   "availableGameStats.achievements.length",
      //   0
      // );
      const mappedWithRarity = get(
        response.data,
        "availableGameStats.achievements",
        []
      ).map((achi) => {
        const rarity = rarities[achi.name] || 0;
        return { ...achi, rarity, unique_key: `${achi.name}_${appid}` };
      }).sort((a, b) => a.rarity - b.rarity);
      setAchievements(mappedWithRarity);
      setCheckedItems(mappedWithRarity.map((item) => {
        return {
          data: item,
          checked: false
        }
      }));
    } catch (error) {
      alert(`something went wrong. ${JSON.stringify(error)}`);
      console.error("error while gettting game data", error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="600px" padding="40px 20px">
        <ModalHeader>Recipe Id: {currentRecipe.id}</ModalHeader>
        <label style={{ marginBottom: "8px" }}>Flex</label>
        <Select
          value={currentRecipe.flexUniqueId}
          onChange={onFlexChange}
          marginBottom="12px"
        >
          {flexes.map((flex) => {
            return (
              <option value={flex.uniqueId} id={flex.uniqueId}>
                {flex.name}
              </option>
            );
          })}
        </Select>
        <label style={{ marginBottom: "8px" }}>Game/Appid</label>
        {/* <ReactSelect
          defaultValue={appid}
          onChange={onAppidChange}
          options={appidOptions}
          isClearable={true}
        /> */}
        <div
         style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
         }}
        >
        <input style={{
          width: "70%",
          padding: "12px 20px",
          margin: "8px 0",
          boxSizing: "border-box",
          border: "1px solid #ccc",
          borderRadius: "4px",
          marginRight: "8px"
        }} type="text"
        placeholder =  'type appid and click get achievements'
        value={appid} onChange={(e) => onAppidChange(e.target.value)} /> 
        <Button onClick={getGameAchievementsByAppid
        } colorScheme='green'>Get Achievements</Button>
        </div>
        <div>{gameData ? `${gameData.gameName} Achievements` : "Game Achievements"}</div>
        <Stack
          direction="column"
          marginTop="8px"
          border="1px solid gray"
          padding="8px"
          maxHeight={400}
          overflowY="scroll"
        >
          {achievements.map(
            (achievement, index) => {
              return (
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    checkedItems[index] = {
                      data: achievements[index],
                      checked: e.target.checked,
                    };
                    setCheckedItems(checkedItems);
                  }}
                  key={index}
                  borderBottom="0.5px solid gray"
                  paddingBottom="16px"
                  paddingTop="16px"
                >
                  {achievement.displayName}[{achievement.name}]-{" "}
                  <span style={{color: 'green', fontWeight: 'bold'}}>
                    {achievement.rarity ? achievement.rarity.toFixed(2) : "_"}%
                  </span>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "gray",
                    }}
                  >
                    <span>{achievement.description}</span>
                  </div>
                </Checkbox>
              );
            }
          )}
        </Stack>
        <ModalFooter marginTop="20px">
          {currentRecipe.id && (
            <Button
              onClick={onDelete}
              ml={3}
              colorScheme="red"
              isLoading={loading}
            >
              Delete
            </Button>
          )}
          <Button onClick={onClose} ml={3} isLoading={loading}>
            Close
          </Button>
          {recipeChanged && (
            <Button
              onClick={onUpdate}
              ml={3}
              colorScheme="green"
              isLoading={loading}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RecipeModal;
