import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Progress,
  Icon,
  useDisclosure,
  Input,
  Button,
  Select,
} from "@chakra-ui/react";

import { uploadAchievement, deleteAchievement } from "../../../api";

AchievementModal.propTypes = {};

function AchievementModal(props) {
  const { isOpen } = props;
  const [currentAchievement, setCurrentAchievement] = useState(
    props.achievement
  );
  const [achievementChanged, setAchievementChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    if (loading) return;
    props.onClose();
  };

  const onUpdate = async () => {
    setLoading(true);
    try {
      await uploadAchievement({ achievement: currentAchievement });
      window.location.reload();
    } catch (error) {
      alert(`something went wrong. ${JSON.stringify(error)}`);
      console.error("error while updating recipe", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmResponse = confirm(
      `are you sure you want to delete this achievement(id: ${currentAchievement.id}?`
    );
    if (confirmResponse) {
      setLoading(true);
      try {
        await deleteAchievement(currentAchievement.id);
        window.location.reload();
      } catch (error) {
        alert(`something went wrong. ${JSON.stringify(error)}`);
        console.error("error while deleteing recipe", error);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const onAchievementChange = (option) => {
    setAchievementChanged(true);
    setCurrentAchievement({
      ...currentAchievement,
      ...option
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="600px" padding="40px 20px">
        <ModalHeader>Achievement Id: {currentAchievement.id}</ModalHeader>
        <label style={{ marginBottom: "4px" }}>name</label>
        <Input
          value={currentAchievement.name}
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ name: event.target.value })
          }
        ></Input>
        <label style={{ marginBottom: "4px" }}>Description</label>
        <Input
          value={currentAchievement.description}
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ description: event.target.value })
          }
        ></Input>
        <label style={{ marginBottom: "4px" }}>apiname</label>
        <Input
          value={currentAchievement.apiname}
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ apiname: event.target.value })
          }
        ></Input>
        <label style={{ marginBottom: "4px" }}>gameName</label>
        <Input
          value={currentAchievement.gameName}
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ gameName: event.target.value })
          }
        ></Input>
        <label style={{ marginBottom: "4px" }}>appid</label>
        <Input
          value={currentAchievement.appid}
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ appid: event.target.value })
          }
        ></Input>
        <label style={{ marginBottom: "4px" }}>Platform</label>
        <Input
          value={currentAchievement.platform}
          readOnly={true}
          marginBottom="16px"
        ></Input>
        <label style={{ marginBottom: "4px" }}>rarity</label>
        <Input
          value={currentAchievement.rarity}
          type="number"
          marginBottom="16px"
          onChange={(event) =>
            onAchievementChange({ rarity: parseFloat(event.target.value, 10) })
          }
        ></Input>
        <ModalFooter marginTop="20px">
          { currentAchievement.id && (
            <Button
            onClick={onDelete}
            ml={3}
            colorScheme="red"
            isLoading={loading}
          >
            Delete
          </Button>
          )}
          <Button onClick={onClose} ml={3} isLoading={loading}>
            Close
          </Button>
          {achievementChanged && (
            <Button
              onClick={onUpdate}
              ml={3}
              colorScheme="green"
              isLoading={loading}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AchievementModal;
