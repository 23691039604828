import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tag,
  Spinner
} from "@chakra-ui/react";

import { getUserData } from '../../api/index';

UserFlex.propTypes = {

};

function UserFlex(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const getUserFlexesData = async () => {
    setLoading(true);
    const response = await getUserData(props.userId);
    if (response && response.user) {
      setUser(response.user);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserFlexesData();
  }, [props.userId]);

  return (
    <div>
      {
        loading
        ? <Spinner size='sm'/>
        : user.flexes && user.flexes.map((flex, index) => (<div key={index}><Tag colorScheme={'blue'} marginBottom='4px' marginTop='4px' flexDirection={'column'} padding='8px 12px'>
        <div>{flex.name}-{flex.flexRarity && (flex.flexRarity * 100).toFixed(2)}%</div>
        <div>Recipe rarity-{flex.recipe.rarity && flex.recipe.rarity.toFixed(2)}</div>
        </Tag></div>))
      }
    </div>
  );
}

export default UserFlex;
