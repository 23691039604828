import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import './styles.scss'
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import {  useLocation } from "react-router-dom";



Index.propTypes = {

};

const paths = [
  // { path: "/admin/achievements", label: "Achievements" },
  { path: "/admin/recipes", label: "Recipes" },
  // { path: "/admin/games", label: "Games" },
  { path: "/admin/common-games", label: "Common Games" },
  { path: "/admin/users", label: "Users" },
];

function Index(props) {
  const location = useLocation();
  const currentIndex = paths.findIndex(path => path.path === location.pathname);
  return (
    <Tabs variant="enclosed" index={currentIndex} marginTop="16px">
      <TabList paddingLeft={"40px"}>
        {paths.map((path) => (
          <Tab key={path.path}>
            <Link to={path.path}>{path.label}</Link>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default Index;
