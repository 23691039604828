import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { Spinner, Center } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Container,
  Button,
  Input
} from "@chakra-ui/react";
import ReactSelect from "react-select";

import UploadAchievement from '../../components/Achievement/uploadAchievement/UploadAchievement';
import AchievementModal from "../../components/Achievement/AchievementModal";
import GameAchievementModal from "../../components/Achievement/GameAchievementModal";


import {getAchievements} from '../../api/index.js'

import './styles.scss'

function AchievementItems({ achievements, onClick }) {
  if (!achievements) return null;
  return achievements.map((achi, index) => {
    return (
      <Tr
        key={index}
        className="t-raws"
        onClick={() => onClick(achi)}
        cursor="pointer"
        _hover={{ backgroundColor: "lightgray" }}
      >
        <Td w={"5%"}>{achi.id}</Td>
        <Td w={"5%"}>{achi.apiname}</Td>
        <Td w={"5%"}>{achi.name}</Td>
        <Td w={"20%"} maxWidth={"200px"}>
          {achi.description}
        </Td>
        <Td w={"5%"}>{achi.rarity ? achi.rarity.toFixed(2) : '-'}%</Td>
        <Td w={"5%"}>{achi.appid}</Td>
        <Td w={"5%"}>{achi.gameName}</Td>
        <Td w={"5%"}>{achi.platform}</Td>
      </Tr>
    );
  });
}

const SearchOptions = [
  { value: "apiname", label: "apiname" },
  { value: "appid", label: "appid" },
  { value: "description", label: "description" },
  { value: "gameName", label: "gameName" },
  { value: "id", label: "id" },
  { value: "name", label: "name" },
  { value: "platform", label: "platform" }
];

const SearchBox = ({searchData, onChange, onApply}) => {

  const onSearchKeyChange = (data) => {
    onChange({
      ...searchData,
      key: data.value,
    })
  };

  const onSearchValueChange = (event) => {
       onChange({
         ...searchData,
         value: event.target.value,
       });
  }

  const onEnter = (event) => {
    if(event.key === 'Enter') {
      onApply()
    }
  }
  return (
    <div
      style={{
        display: "flex",
        maxWidth: "900px",
        marginLeft: "80px",
        width: "50%",
      }}
    >
      <ReactSelect
        defaultValue={null}
        onChange={onSearchKeyChange}
        options={SearchOptions}
        placeholder="Select Colmun"
      />
      <Input
        placeholder="Search"
        value={searchData.value}
        marginLeft="8px"
        marginRight="8px"
        width="300px"
        onChange={onSearchValueChange}
        onKeyDown={onEnter}
      />
      <Button colorScheme={"blue"} onClick={onApply}>
        Search
      </Button>
    </div>
  );
};


const Achievement = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20)
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [isAppidModalOpen, setIsAppidModalOpen] = useState(false);
  const [searchData, setSearchData] = useState({key: '', value: ''})

    const onSelectRow = (achievement) => {
      setSelectedAchievement(achievement);
    };

    const onAddRecipe = () => {
      // fields to add
      // apiname: "achievement_star_struck";
      // appid: "281990";
      // description: "Own 200 Starbases (Outposts are counted)";
      // gameName: "Stellaris";
      // id: 831;
      // name: "Star Struck";
      // platform: "Steam";
      // rarity: 6.3;
      setSelectedAchievement({
        apiname: "",
        appid: "",
        description: "",
        gameName: "",
        name: "",
        platform: "Steam",
        rarity: null
      });
    };

  const getData = async () => {
    setLoading(true);
    try {
      const opts = { page, pageSize };
      let filters = []
      if(searchData.key && searchData.value) {
        filters.push(`key=${searchData.key}`)
        filters.push(`value=${searchData.value}`)
      }
      const data = await getAchievements(opts, filters);
      setData(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const onFilterApply = () => {
    setPage(0);
    getData();
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPage(event.selected)
  };

  const renderTable = () => {
    if (!data || loading) return null;
     return (
       <div
         className="user-container"
         style={{ padding: "28px 64px", paddingBottom: "0px" }}
       >
         <Button
           colorScheme="green"
           style={{
             position: "absolute",
             top: "84px",
             right: "40px",
             borderRadius: "16px",
           }}
           onClick={onAddRecipe}
         >
           Add Achievement
         </Button>
         {selectedAchievement && (
           <AchievementModal
             isOpen={!!selectedAchievement}
             achievement={selectedAchievement}
             onClose={() => setSelectedAchievement(null)}
           />
         )}
         <Button
           colorScheme="green"
           style={{
             position: "absolute",
             top: "140px",
             right: "40px",
             borderRadius: "16px",
           }}
           onClick={() => setIsAppidModalOpen(true)}
         >
           Add Achievement with appid/game
         </Button>
         {isAppidModalOpen && (
           <GameAchievementModal
             isOpen={!!isAppidModalOpen}
             onClose={() => setIsAppidModalOpen(null)}
           />
         )}
         <TableContainer>
           <Table variant="simple" size={"sm"} marginBottom={16}>
             <Thead>
               <Tr>
                 <Th>id</Th>
                 <Th>apiname</Th>
                 <Th>name</Th>
                 <Th maxWidth={"200px"}>description</Th>
                 <Th>rarity</Th>
                 <Th>appid</Th>
                 <Th>gameName</Th>
                 <Th>platform</Th>
               </Tr>
             </Thead>
             <Tbody>
               {
                 <AchievementItems
                   achievements={data.achievements}
                   onClick={onSelectRow}
                 />
               }
             </Tbody>
           </Table>
           <div>
             <div>Total:{data.total} </div>
             <div> Page: {page + 1} </div>
           </div>
         </TableContainer>
         <UploadAchievement />
       </div>
     );
  }


  return (
    <>
      {/* <MetaTags title="AdminUsers" description="AdminUsers page" /> */}
      <Container maxW={"100%"} paddingBottom={"80px"}>
        <h1 className="heading">Achievements</h1>
        <SearchBox searchData={searchData} onChange={setSearchData} onApply={onFilterApply}/>
        {renderTable()}
        <div
        // style={{
        //   display: "flex",
        // }}
        >
          <ReactPaginate
            className="pagination"
            // breakLabel="..."
            initialPage={0}
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={data.total ? data.total / pageSize : 0}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          />
        </div>
        {loading && (
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              margin="auto"
              alignContent={"center"}
            />
          </Center>
        )}
      </Container>
    </>
  );
};

export default Achievement;
