import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

GenreChart.propTypes = {};

function getRandomRgba() {
  const x = Math.floor(Math.random() * 256);
  const y = 100 + Math.floor(Math.random() * 256);
  const z = 50 + Math.floor(Math.random() * 256);
  const bgColor = "rgb(" + x + "," + y + "," + z + ")";
  return bgColor;
}

const genreColors = {};
const getGenreColor = (year) => {
  if (genreColors[year]) {
    return genreColors[year];
  } else {
    genreColors[year] = getRandomRgba();
    return genreColors[year];
  }
};

function GenreChart(props) {
  const { genreData } = props;
  // generate array for genre Dict
  const genreArray = Object.keys(genreData).map((key) => {
    return {
      genre: key,
      count: genreData[key],
    };
  });
  const labels = genreArray.map((item) => item.genre);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Total Achievements",
        data: genreArray.map((genre) => genre.count),
        backgroundColor: '#808080',
        borderWidth: 1,
      },
    ],
  };
  return <Bar options={options} data={data} />;
}

export default GenreChart;
