import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { Bubble } from 'react-chartjs-2';
import ReactSelect from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import _ from 'lodash'

import './styles.scss'

BubbleChart.propTypes = {

};

const appColors = {}

function randomRgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}

const getAppColor = (appid) => {
  if(appColors[appid]) {
    return appColors[appid]
  } else {
    appColors[appid] = randomRgba()
    return appColors[appid]
  }
}

const getDataPoints = (achievements) => {
    if (achievements.length === 0) return [];
     const getDaysArray = function (start, end) {
       for (
         var arr = [], dt = new Date(start);
         dt <= new Date(end);
         dt.setDate(dt.getDate() + 1)
       ) {
         arr.push(moment(dt).format("yyyy-MM-DD"));
       }
       return arr;
     };
     const dateArray = getDaysArray(
       achievements[0].unlockDate,
       achievements[achievements.length - 1].unlockDate
     );
  const mappedAchievements = dateArray.reduce((acc, date)=> {
    acc[date] = undefined
    return acc
  }, {})
  achievements.forEach((achi) => {
    const achiDate = achi.unlockDate
    if(mappedAchievements[achiDate]){
      mappedAchievements[achiDate] = [...mappedAchievements[achiDate], achi]
    } else {
      mappedAchievements[achiDate] = [achi]
    }
  })
  let mappedAchievementArray = []
  Object.keys(mappedAchievements).forEach((date) => {
    if(mappedAchievements[date]){
      mappedAchievementArray = [
        ...mappedAchievementArray,
        ...mappedAchievements[date],
      ];
    } else {
       mappedAchievementArray = [
         ...mappedAchievementArray,
        {unlockDate: date, percent: 0},
       ];
    }
  })
  return mappedAchievementArray
};


const genreOptions = [
  { label: "Action", value: "Action" },
  { label: "Adventure", value: "Adventure" },
  { label: "Casual", value: "Casual" },
  { label: "Massively Multiplayer", value: "Massively Multiplayer" },
  { label: "Racing", value: "Racing" },
  { label: "RPG", value: "RPG" },
  { label: "Simulation", value: "Simulation" },
  { label: "Sports", value: "Sports" },
  { label: "Strategy", value: "Strategy" },
];

function BubbleChart(props) {
  const {achievements = []} = props
  const chartRef = useRef(null);
  const [filteredAchievement, setFilteredAchievements] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const [selectedGenres, setSelectedGenres] = React.useState(null)
  const [mappedAchievementArray, setMappedAchievementArray] = React.useState([])
  const [chartFilters, setChartFilters] = React.useState({
    genre: [],
  })


  useEffect(() => {
    setLoading(true)
    if(achievements && achievements.length){
      // setFilteredAchievements([])
      const newFilteredAchievements = achievements.filter((achi) => {
        for (let index = 0; index < chartFilters.genre.length; index++) {
          const genre = chartFilters.genre[index];
          if (!achi[genre]) {
            return false;
          }
        }
        return true
      })
      setFilteredAchievements(newFilteredAchievements);
    }
    setLoading(false)
  }, [achievements, chartFilters]);

  useEffect(() => {
    setMappedAchievementArray(null);
    const mapped = getDataPoints(filteredAchievement);
    setMappedAchievementArray(mapped);
  }, [filteredAchievement]);


  const getBubbleDataSet = () => {
    const datasets = mappedAchievementArray.map((achi, index) => {
      let radius = achi.percent
        ? parseInt(Math.log(Math.abs(achi.percent - 100)) * 2)
        : 0;
      if(radius > 8) {
        // if the achievemet is rare make the bubble size bigger.
        radius = radius * 2
      }
      return {
        label: "",
        data: [
          {
            x: achi.unlockDate,
            y: index,
            // the more rare the achievement is, let's make the bubble bigger.
            r: radius,
            // r: parseInt(achi.percent / 100 * 10),
          },
        ],
        backgroundColor: getAppColor(achi.appid),
      };
    });
    // setLoading(false);
    return datasets;
  }

  const getGraphOptions = () => {
    const minX = _.get(filteredAchievement, '[0].unlockDate') ? moment(filteredAchievement[0].unlockDate).format("YYYY") : '2010';
    const maxX = _.get(filteredAchievement, `${filteredAchievement[filteredAchievement.length - 1]}.unlockDate`) ? moment(
      filteredAchievement[filteredAchievement.length - 1].unlockDate
    ).format("YYYY"): '2022';

    const options = {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "year",
          },
          min: minX,
          max: maxX,
        },
        y: {
          title: {
            display: true,
            text: "Cumulative Total",
          },
          // min: 0,
          // max: 100,
        },
      },
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: function (data) {
              const items = [];
              data.forEach((item) => {
                items.push(mappedAchievementArray[item.datasetIndex]);
              });
              return items
                .map((item) =>
                  item.achiName
                    ? `Achieved ${item.achiName} (${
                        item.percent ? item.percent.toFixed(2, 10) : "-"
                      }%)`
                    : ""
                )
                .join(" ");
            },
            label: function (context) {
              const achi = mappedAchievementArray[context.datasetIndex];
              return achi.gameName
                ? `While playing ${achi.gameName} on ${moment(
                    achi.unlockDate
                  ).format("DD MMM, yyyy")}`
                : "";
            },
          },
        },
      },
    };
    return options
  }


  const onGenreFilterChange = (genres) => {
    setFilteredAchievements([]);
    setLoading(true)
    setSelectedGenres(genres);
    setChartFilters({ ...chartFilters, genre: genres.map((genre) => genre.value) });

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  if(loading) return (
    <div className='clip-loader-bubble'>
      <ClipLoader color="#36d7b7" />
    </div>
  );

  const options = getGraphOptions()
  const datasets = getBubbleDataSet()
  return (
    <div className="record-container">
      <div className="genre-filter">
        {/* <div className="filter-label">By Game</div> */}
        <ReactSelect
          className="genre-select"
          // defaultInputValue='Select Genre'
          placeholder="Select Genre"
          defaultValue={selectedGenres}
          onChange={onGenreFilterChange}
          options={genreOptions}
          isMulti={true}
        />
      </div>
      {filteredAchievement && filteredAchievement.length ? (
        <Bubble
          options={options}
          data={{ datasets }}
          ref={chartRef}
          // redraw={true}
        />
      ): 'No filter matches any data.'}
    </div>
  );
}

export default BubbleChart;
