import React, { useEffect, useState } from "react";
import { Spinner, Center } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Input
} from "@chakra-ui/react";

import { getGames, addGame } from "../../api/index";
// import AchievementLabel from "../../components/Recipe/AchievementLabel";
// import RecipeModal from "../../components/Recipe/RecipeModal";

const GamesPage = () => {
  const [loading, setLoading] = useState(false);
  const [appid, setAppid] = useState("");
  const [achievements, setAchievements] = useState([]);
  const [data, setData] = useState({});
  const [flexes, setFlexes] = useState([]);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const getGamesData = async () => {
    setLoading(true);
    const response = await getGames();
    if (response && response.games) {
      setFlexes(response.games);
    }
    setLoading(false);
  };

  // const getAchievementData = async () => {
  //   setLoading(true);
  //   const response = await getAchievements();
  //   if (response && response.achievements) {
  //     setAchievements(response.achievements);
  //   }
  //   setLoading(false);
  // };

  // const getRecipeData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await getGamesData();
  //     setData(response);
  //   } catch (e) {}
  //   setLoading(false);
  // };

  useEffect(() => {
    getGamesData();
    // getRecipeData();
    // getAchievementData();
  }, []);

  const onSelectRow = (recipe) => {
    setSelectedRecipe(recipe);
  };

  const onAddGame = async () => {
    setLoading(true);
    const response = await addGame(appid);
    if (response && response.game) {
      window.location.reload()
    }
    setLoading(false);
  };

  return (
    <>
      {/* <MetaTags title="AdminUsers" description="AdminUsers page" /> */}
      <Button
        colorScheme={"green"}
        onClick={onAddGame}
        style={{ position: "fixed", top: "54px", right: "20px" }}
      >
        Add game
      </Button>
      {data && (
        <div className="user-container" style={{ padding: "80px 64px" }}>
          {selectedRecipe &&
            {
              /* <RecipeModal
              isOpen={!!selectedRecipe}
              recipe={selectedRecipe}
              onClose={() => setSelectedRecipe(null)}
              flexes={flexes}
              achievements={achievements}
            /> */
            }}
          <TableContainer>
            Total: {data.total}
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>id</Th>
                  <Th>Flex unique_id</Th>
                  <Th>achievemets</Th>
                  <Th>action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.games &&
                  data.games.map((recipe, index) => {
                    return (
                      <Tr
                        key={index}
                        onClick={() => onSelectRow(recipe)}
                        cursor="pointer"
                        _hover={{ backgroundColor: "lightgray" }}
                      >
                        <Td>{recipe.id}</Td>
                        <Td>{recipe.flexUniqueId}</Td>
                        <Td maxWidth={"500px"}>
                          {/* {achievements && achievements.length ? (
                            <AchievementLabel
                              items={recipe.achievements}
                              achievements={achievements}
                            />
                          ) : null} */}
                        </Td>
                        {/* <Td>{getState(user)}</Td> */}
                        {/* <Button>Save</Button> */}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <label style={{ marginBottom: "4px" }}>Insert APPID here:</label>
          <Input
            value={appid}
            marginBottom="16px"
            onChange={(event) => setAppid(event.target.value)}
          ></Input>
          <Button
            onClick={onAddGame}
            ml={3}
            colorScheme="green"
            isLoading={loading}
            marginBottom="16px"
          >
            Add game
          </Button>
        </div>
      )}
      {loading && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            margin="auto"
            alignContent={"center"}
          />
        </Center>
      )}
    </>
  );
};

export default GamesPage;
