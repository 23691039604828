import axios from "axios";

const getHeaders = async () => {
  // eslint-disable-next-line no-undef
  const token  = await Clerk.session.getToken({ template: "basic" });
  return {
    headers: {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
    },
  };
}

export const addGame = async (appid) => {
    const headers = await getHeaders();
    const response = await axios.post(
      `${process.env.REACT_APP_API_END_POINT}/addGame?appid=${appid}`,
      {},
      headers
    );
    return response.data;
}

export const  getGames = async () => {
 const headers = await getHeaders();
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getGames`,
    headers
  );
  return response.data
}

export const getCommonGames = async () => {
  const headers = await getHeaders();
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getCommonGames`,
    headers
  );
  return response.data
}

export const getUsers = async (opts) => {
    const { page, pageSize, gameId } = opts || {};
    const queryArray = [];
    if (page === 0 || page) queryArray.push(`page=${page}`);
    if (pageSize) queryArray.push(`pageSize=${pageSize}`);
    if (gameId) queryArray.push(`gameId=${gameId}`);
    const headers = await getHeaders();
    const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getUsers?${queryArray.join('&')}`,
    headers
  );
  return response.data
}

export const getUserData = async (userId) => {
  const headers = await getHeaders();
  const source = 'steam'
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getUser?gameId=${userId}&source=${source}`,
    headers
  );
  return response.data
}


export const getFlexes = async (opts) => {
  const headers = await getHeaders();
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getFlexes`,
    headers
  );
  return response.data
}

export const getGameAchievements = async (appid) => {
   const headers = await getHeaders();
   const response = await axios.get(
     `${
       process.env.REACT_APP_API_END_POINT
     }/getGameAchievements?appid=${appid}`,
     headers
   );
   return response.data;
}

export const getAchievements = async (opts, filters=[]) => {
  const {page, pageSize} = opts || {}
  const queryArray = [...filters]
  if(page === 0 || page) queryArray.push(`page=${page}`)
  if(pageSize) queryArray.push(`pageSize=${pageSize}`)
  const headers = await getHeaders();
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getAchievements?${queryArray.join('&')}`,
    headers
  );
  return response.data;
}


// Get app global achievements
export const getGlobalAchievements = async (appid) => {
   const headers = await getHeaders();
   const response = await axios.get(
     `${process.env.REACT_APP_API_END_POINT}/getGlobalAchievements?appid=${appid}`,
     headers
   );

   return response.data;
}

export const uploadAchievement = async ( data ) => {
  const headers = await getHeaders();
  const response = await axios.post(
    `${process.env.REACT_APP_API_END_POINT}/uploadAchievement`,
    data,
    headers
  );

  return response.data;
};

export const deleteAchievement = async (achievementId) => {
  const headers = await getHeaders();
  const response = await axios.delete(
    `${process.env.REACT_APP_API_END_POINT}/deleteAchievement?achievementId=${achievementId}`,
    headers
  );

  return response.data;
};


export const getRecipes = async (opts) => {
  const { page, pageSize } = opts || {};
  const queryArray = [];
  if (page) queryArray.push(`page=${page}`);
  if (pageSize) queryArray.push(`pageSize=${pageSize}`);
  const headers = await getHeaders();
  const response = await axios.get(
    `${process.env.REACT_APP_API_END_POINT}/getRecipes?${queryArray.join('&')}`,
    headers
  );
  return response.data;
};

export const updateRecipe = async (data) => {
  const headers = await getHeaders();
  const response = await axios.post(
    `${process.env.REACT_APP_API_END_POINT}/upsertRecipe`,
    data,
    headers
  );

  return response.data;
};

export const deleteRecipe = async (recipeId) => {
  const headers = await getHeaders();
  const response = await axios.delete(
    `${process.env.REACT_APP_API_END_POINT}/deleteRecipe?recipeId=${recipeId}`,
    headers
  );

  return response.data;
};

