import React, { useEffect, useState } from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import { Tag } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { Tooltip } from "@chakra-ui/react";
import _ from 'lodash';

import './styles.scss'

const color = ["blue", "red", "green", "yellow", "gray", "orange"];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


UserSummary.propTypes = {

};

const getToolTip = (flex) => {
  let recipeRarity = 0
  let gameNames = []
  const { recipe } = flex
  if (recipe.achievements && recipe.achievements.length) {
    recipe.achievements.forEach((achi) => {
      if (achi.rarity) {
        recipeRarity = recipeRarity
          ? (recipeRarity * achi.rarity) / 100
          : achi.rarity / 100;
      }
      if (!gameNames.includes(achi.gameName)) {
        gameNames.push(achi.gameName);
      }
    });
  }
  if (gameNames.length < 2) {
    return `You got this Flex with our Recipe with Rarity ${recipeRarity.toFixed(
      2,
      10
    )}. It indicates ${flex.name} in the games you have played, like ${
      gameNames[0]
    }.`;
  }
  return `You got this Flex with our Recipe with Rarity ${recipeRarity.toFixed(
    2,
    10
  )}. It indicates ${flex.name} in the games you have played, like ${gameNames
    .slice(0, gameNames.length - 1)
    .join(", ")} and ${gameNames[gameNames.length - 1]}.`;
}

function UserSummary(props) {
    const { gamerId, achievements = [], gamesData, averageRarity, setFlexAchievements } = props;
    const [data, setData] = useState({});
    const [flexes, setFlexes] = useState(null);
    const updateFlexAchievements = (flexes) => {
      if(!flexes || flexes.length == 0) {
        setFlexAchievements({})
      } else {
        const achiDictToUpdate = {}
        for (let index = 0; index < flexes.length; index++) {
          const flex = flexes[index];
          const achievements = _.get(flex, 'recipe.achievements', [])
          achievements.forEach((achi) => {
            achiDictToUpdate[`${achi.apiname}_${achi.appid}`] = true
          })
        }
        setFlexAchievements(achiDictToUpdate);
      }
     }

    const getUserSummary = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ENGINE_ENDPOINT}/user?gamerId=${gamerId}`
        );
        setData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };

     const getUserFlexes = async () => {
       const response = await axios.get(
         `${process.env.REACT_APP_ENGINE_ENDPOINT}/flex?gamerId=${gamerId}`
       );
       const flexes = response.data.map((flex) => {
         // turning uniqueId to name
         return {
           name: flex.flex.split("_").slice(1).join(" "),
           ...flex,
         };
       });
       updateFlexAchievements(flexes);
       setFlexes(flexes);
     };

    useEffect(() => {
      getUserSummary();
      getUserFlexes()
    }, []);

  return (
    <div className="user-summary-container">
      <div className="left-section">
        <div>
          <div className="avatar-container">
            <img src={data?.avatar} alt="steam logo" className="logo" />
          </div>
          <div className="user-name">
            Hello,{" "}
            <span>
              {data.persona_name ? data.persona_name : data.real_name}
            </span>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="user-overview">
          <div className="title">Profile Overview</div>
          <div className="details">
            <div className="card-container">
              <div className="card">
                <div className="card-title">Total Games</div>
                <div className="card-value">{data.totalGames}</div>
              </div>
              <div className="card">
                <div className="card-title">Achievement Analysed</div>
                <div className="card-value">
                  {achievements && achievements.length}
                </div>
              </div>
              <div className="card">
                <div className="card-title">Completion Rate</div>
                <div className="card-value">
                  {gamesData &&
                    gamesData.avergeGameCompletionRate &&
                    (gamesData.avergeGameCompletionRate * 100).toFixed(2, 10)}
                  %{" "}
                </div>
              </div>
              <div className="card">
                <div className="card-title">Avg. Achievement Rarity</div>
                <div className="card-value">
                  {averageRarity && Math.ceil(averageRarity)}%
                </div>
              </div>
              <div className="card">
                <div className="card-title">Total Time Played</div>
                <div className="card-value">
                  {gamesData && gamesData.totalHoursPlayed &&
                    parseInt(gamesData.totalHoursPlayed)}{" "}
                  Hours
                </div>
              </div>
            </div>
            <div className="flex-container">
              <div className="subtitle">Flexes</div>
              <div className="flexes">
                {flexes ? (
                  flexes.map((flex, index) => (
                    <Tag
                      size={"lg"}
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      colorScheme={color[index % 6]}
                      marginRight={4}
                      marginBottom={4}
                      className="flex-box"
                    >
                      <Tooltip label={getToolTip(flex)} aria-label="A tooltip">
                        {capitalizeFirstLetter(flex.name)}
                      </Tooltip>
                    </Tag>
                  ))
                ) : (
                  <div style={{ marginTop: "80px" }}>
                    <ClipLoader color="#36d7b7" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserSummary;
