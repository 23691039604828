import React, {useEffect, useState} from 'react'
import { Spinner, Center } from '@chakra-ui/react'
import get from 'lodash/get'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button
} from '@chakra-ui/react'


import { getFlexes, getRecipes } from "../../api/index";
import AchievementLabel from "../../components/Recipe/AchievementLabel";
import RecipeModal from '../../components/Recipe/RecipeModal'


const RecipesPage = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [flexes, setFlexes] = useState([])
  const [selectedRecipe, setSelectedRecipe ] = useState(null);

  const getFlexesData = async () => {
    setLoading(true);
    const response = await getFlexes();
    if (response && response.flexes) {
      setFlexes(response.flexes);
    }
    setLoading(false);
  };

  // const getAchievementData = async () => {
  //   setLoading(true)
  //   const response = await getAchievements()
  //   if (response && response.achievements) {
  //     setAchievements(response.achievements);
  //   }
  //   setLoading(false)
  // }



  const getRecipeData = async () => {
    setLoading(true)
    try {
      const response = await getRecipes()
      setData(response);
    } catch (e) {}
    setLoading(false)
  }

  useEffect(() => {
    getFlexesData()
    getRecipeData()
    // getAchievementData()
  }, [])

  const onSelectRow = (recipe) => {
    setSelectedRecipe(recipe);
  }

  const onAddRecipe = () => {
    setSelectedRecipe({
      flexUniqueId: null,
      achievements: []
    })
  }

  return (
    <>
      {/* <MetaTags title="AdminUsers" description="AdminUsers page" /> */}
      <Button colorScheme={"green"} onClick={onAddRecipe} style={{position:'fixed', top: '54px', right: '20px'}}>
        Add Recipe
      </Button>
      {data && (
        <div className="user-container" style={{ padding: "80px 64px" }}>
          {selectedRecipe && (
            <RecipeModal
              isOpen={!!selectedRecipe}
              recipe={selectedRecipe}
              onClose={() => setSelectedRecipe(null)}
              flexes={flexes}
            />
          )}
          <TableContainer>
            Total: {data.total}
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>id</Th>
                  <Th>Flex unique_id</Th>
                  <Th>achievemets</Th>
                  <Th>action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.recipes &&
                  data.recipes.map((recipe, index) => {
                    return (
                      <Tr
                        key={index}
                        onClick={() => onSelectRow(recipe)}
                        cursor="pointer"
                        _hover={{ backgroundColor: "lightgray" }}
                      >
                        <Td>{recipe.id}</Td>
                        <Td>{recipe.flexUniqueId}</Td>
                        <Td maxWidth={"500px"}>
                          {recipe.achievementKeys && recipe.achievementKeys.length ? (
                            <AchievementLabel
                              achievementKeys={recipe.achievementKeys}
                              // achievements={achievements}
                            />
                          ) : null}
                        </Td>
                        {/* <Td>{getState(user)}</Td> */}
                        {/* <Button>Save</Button> */}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      )}
      {loading && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            margin="auto"
            alignContent={"center"}
          />
        </Center>
      )}
    </>
  );
}

export default RecipesPage;
