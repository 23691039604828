import React from 'react';
import PropTypes from 'prop-types';

import {
  Tag,
  TagLabel,
} from '@chakra-ui/react'
// import SelectSearch from 'react-select-search';
import _ from 'lodash'

Index.propTypes = {

};

function Index({ achievementKeys }) {
  // const itemsArray = items
  //   .map((id) => {
  //     return achievements.find((achievement) => achievement.id === id);
  //   })
  //   .filter((item) => item);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {achievementKeys &&
        achievementKeys.length &&
        achievementKeys.map((key, index) => {
          return (
            <Tag
              key={index}
              flexWrap="wrap"
              marginRight={8}
              paddingRight={4}
              marginTop={4}
            >
              <TagLabel>
                {key}
              </TagLabel>
              {/* <TagCloseButton /> */}
            </Tag>
          );
        })}
      {achievementKeys.length === 0 && (
        <div>
          no achievement reference found for achievementIds{" "}
          {JSON.stringify(achievementKeys)}
        </div>
      )}
    </div>
  );
}

export default Index;
