import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from "react-chartjs-2";
import moment from "moment";

ScatterChart.propTypes = {

};

function ScatterChart(props) {
  const { achievements = [], flexAchiDict } = props;
  if (!achievements || achievements.length === 0 || !flexAchiDict) return null;
    const options = {
      responsive: true,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "year",
          },
          min: moment(achievements[0].unlockDate).format("YYYY"),
          max: moment(achievements[achievements.length - 1].unlockDate).format(
            "YYYY"
          ),
        },
        y: {

          title: {
            display: true,
            text: 'Achievement Percent/Rarity'
          },
          min: 0,
          max: 100,
        },
      },
      legend: {
        display: false,
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: function (data) {
              const items = [];
              console.log(data)
              data.forEach((item) => {
                items.push(achievements[item.datasetIndex]);
              });
              items.map((item) => item.achiName).join(" ");
              return items
                .map(
                  (item) =>
                    `${item.achiName} (${
                      item.percent ? item.percent.toFixed(2, 10) : "-"
                    }%)`
                )
                .join(" ");
            },
            label: function (context) {
              // console.log('contennt', context)
            },
          },
        },
        legend: { display: false },
        title: {
          display: true,
          text: "",
          position: "top",
        },
      },
    };

      const getBubbleDataSet = () => {
        if(!achievements || achievements.length === 0) return null
        const datasets = achievements.map((achi, index) => {
          return {
            label: "",
            data: [
              {
                x: achi.unlockDate,
                y: parseInt(achi.percent),
                // the more rare the achievement is, let's make the bubble bigger.
                r: flexAchiDict[achi.unique_achievement_key] ? 5 : 3,
                // r: parseInt(achi.percent / 100 * 10),
              },
            ],
            borderColor: flexAchiDict[achi.unique_achievement_key]
              ? "#000000" : "rgb(255, 99, 132)",
            backgroundColor: flexAchiDict[achi.unique_achievement_key]
              ? "#000000"
              : "rgba(255, 99, 132, 0.5)",
          };
        });
        return datasets;
      };
    const data = {
      // labels: achievements ? achievements.map((achi) => '') : [],
      datasets: getBubbleDataSet(),
    };
  return <Bubble options={options} data={data} />;
}

export default ScatterChart;
