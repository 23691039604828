import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import "chartjs-adapter-moment";
import queryString from 'query-string'
import axios from "axios";
import { Heading } from '@chakra-ui/react'
import HashLoader from "react-spinners/HashLoader";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
  CategoryScale,
  BarElement,
} from "chart.js";

import BubbleChart from './BubbleChart';
import ScatterChart from './ScatterChart';
import AchiActivityChart from './AchiActivityChart';
import UserSummary from './UserSummary';
import GenreChart from './GenreChart';
import Filters from './Filters';


import './styles.scss'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
  CategoryScale,
  BarElement
);

UserProfile.propTypes = {

};

const mapGameUserData = (gamesDataArray) => {
  // calculate game completion rate
  let gamesWithAchievement = 0
  let gameCompletionRate = 0
  let totalHoursPlayed = 0
  gamesDataArray.forEach((game) => {
    if (game.gameCompletionRate)  {
      gamesWithAchievement++;
    }
    gameCompletionRate = gameCompletionRate + game.gameCompletionRate / 100;

    totalHoursPlayed = totalHoursPlayed + game.playTimeInHours;
  });

  const avergeGameCompletionRate = gameCompletionRate / gamesWithAchievement;
  // calculate genre

  return { avergeGameCompletionRate, totalHoursPlayed, games: gamesDataArray };
}

  const mapAchievementData = (achievementDataArray) => {
    let acheivementRarityTotal = 0
    let acheivementRarityCount = 0
    const mappedAchievements = achievementDataArray.map((achi) => {
      if(achi.achievementRarity) {
        acheivementRarityTotal =
          acheivementRarityTotal + achi.achievementRarity;
          acheivementRarityCount++;
      }
      return {
        ...achi,
        unlockDate: achi.unlock_date,
        achiName: achi.achiName,
        percent: achi.achievementRarity,
        unlockTime: achi.unlocktime,
        appid: achi.appid,
        gameName: achi.gameName,
      };
    });
    const sortedAchievements = mappedAchievements.sort(
      (a, b) => a.unlockTime - b.unlockTime
    );
    const averageAchiRarity = acheivementRarityTotal / acheivementRarityCount;
    return { sortedAchievements, averageAchiRarity};
  };

function UserProfile(props) {
  const parsed = queryString.parse(window.location.search)
  const gamerId = parsed.gamerId
  const [achievements, setAchievements] = useState(null)
  const [filteredAchievements, setFilteredAchievements] = useState(null)
  const [showCharts, setShowCharts] = useState(false)
  const [achiYearCount, setAchiYearCount] = useState(null)
  const [gamesData, setGamesData] = useState({})
  const [genreData, setGenreData] = useState(null)
  const [avgRarity, setAverageRarity] = useState(null)
  const [achievementLoading, setAchievementLoading] = useState(false)
  const [gamesLoading, setGamesLoading] = useState(false)
  const [yearRange, setYearRange] = useState(null)
  const [flexAchiDict, setFlexAchiDict] = useState(null)
  const [filters, setFilters] = useState({
    percent: {
      min: 0,
      max: 100,
    },
    year: {
      min: "",
      max: "",
    },
    gameHours: 0,
    games: undefined,
  });

  useEffect(() => {
    if(achievements && achievements.length) {
       const newAchievements = achievements.filter((achi) => {
         // if no unlock time, then let's avoid that achievement
         if (!achi.unlockTime) {
           return false;
         }
         return true;
       });
       const yearStart = parseInt(moment(newAchievements[0].unlockDate).format("YYYY"));
       const yearEnd = parseInt(moment(newAchievements[newAchievements.length - 1].unlockDate).format('YYYY'));
       setYearRange([yearStart, yearEnd]);
       setFilters({
         ...filters,
         year: {
           min: yearStart,
           max: yearEnd,
         },
       });
    }
  }, [achievements]);

  const isItInFilterGame = (achi) => {
    if (!filters.games) {
      return true;
    }
    return !! filters.games[achi.appid];
  }

  useEffect(() => {
    setShowCharts(false)
    if (achievements) {
      const achiYearWiseCount = {}
          const genreDict = {
            Action: 0,
            Adventure: 0,
            Casual: 0,
            //   "Indie": 0,
            "Massively Multiplayer": 0,
            Racing: 0,
            RPG: 0,
            Simulation: 0,
            Sports: 0,
            Strategy: 0,
          };
      const newAchievements = achievements.filter(
        (achi) => {
          const isFilterYes = // filter the new achievemets
            achi.percent >= filters.percent.min &&
            achi.percent <= filters.percent.max &&
            parseInt(moment(achi.unlockDate).format("YYYY")) >=
              filters.year.min &&
            parseInt(moment(achi.unlockDate).format("YYYY")) <=
              filters.year.max &&
            achi.playTimeInHours >= filters.gameHours &&
            isItInFilterGame(achi);

          if(!isFilterYes || !achi.unlockTime) return false

          // calculate genres
          if (achi["Action"]) {
            genreDict["Action"] = genreDict["Action"] + achi["Action"];
          }
          if (achi["Adventure"]) {
            genreDict["Adventure"] = genreDict["Adventure"] + achi["Adventure"];
          }
          if (achi["Casual"]) {
            genreDict["Casual"] = genreDict["Casual"] + achi["Casual"];
          }
          // if(game['Indie']) {
          //   genreDict['Indie'] = genreDict['Indie'] + game['Indie']

          // }
          if (achi["Massively Multiplayer"]) {
            genreDict["Massively Multiplayer"] =
              genreDict["Massively Multiplayer"] +
              achi["Massively Multiplayer"];
          }
          if (achi["Racing"]) {
            genreDict["Racing"] = genreDict["Racing"] + achi["Racing"];
          }
          if (achi["RPG"]) {
            genreDict["RPG"] = genreDict["RPG"] + achi["RPG"];
          }
          if (achi["Simulation"]) {
            genreDict["Simulation"] =
              genreDict["Simulation"] + achi["Simulation"];
          }
          if (achi["Sports"]) {
            genreDict["Sports"] = genreDict["Sports"] + achi["Sports"];
          }
          if (achi["Strategy"]) {
            genreDict["Strategy"] = genreDict["Strategy"] + achi["Strategy"];
          }

          const unlockTimeYear = moment(achi.unlockDate).format("YYYY");
          // apply year calculation here.

          // counting achievement in a year for yearly achievemet graph.
          if (
            achi.percent >= filters.percent.min &&
            achi.percent <= filters.percent.max
          ) {
            if (!achiYearWiseCount[unlockTimeYear]) {
              achiYearWiseCount[unlockTimeYear] = 1;
            } else {
              achiYearWiseCount[unlockTimeYear] += 1;
            }
          }

        return isFilterYes;
        }
      );
      setGenreData(genreDict);
      const achiYearWiseCountArray = Object.keys(achiYearWiseCount).map((year) => { return {year, count: achiYearWiseCount[year]}})
      setAchiYearCount(achiYearWiseCountArray)
      setFilteredAchievements(newAchievements);
    }
    setShowCharts(true);
  }, [filters, achievements]);


  const getUserAchievement = async () => {
    setAchievementLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENGINE_ENDPOINT}/achievements?gamerId=${gamerId}`
      );
      const { sortedAchievements, averageAchiRarity } = mapAchievementData(
        response.data
      );
      setAverageRarity(averageAchiRarity);
      setAchievements(sortedAchievements);
    } catch (error) {
      console.error(error);
    }
    setAchievementLoading(false)
  }

  const getUserGameData = async () => {
    setGamesLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENGINE_ENDPOINT}/usergames?gamerId=${gamerId}`
      );
      const mappedGameData = mapGameUserData(response.data);
      setGamesData({...gamesData, ...mappedGameData});
    } catch (error) {
      console.error(error);
    }
    setGamesLoading(false)
  }

  useEffect(() => {
    if(gamerId) {
      getUserAchievement()
      getUserGameData()
    }
  }, [])

  if(!gamerId) {
    return <div>Gamer Id is missing.</div>
  }

  const onFilterChange = (changedFilterObject) => {
     setShowCharts(false);
       setFilters({
         ...filters,
         ...changedFilterObject
       });
  }

  if(achievementLoading || gamesLoading ) {
    return (
      <div className="main-loader">
        <HashLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <div className="profile-container">
      <UserSummary
        gamerId={gamerId}
        achievements={achievements}
        gamesData={gamesData}
        averageRarity={avgRarity}
        setFlexAchievements={setFlexAchiDict}
      />
      <Filters
        onFilterChange={onFilterChange}
        filters={filters}
        yearRange={yearRange}
        gamesData={gamesData}
      />
      <div className="top-row">
        <div className="genre-overview">
          <div className="subtitle">Achievements By Game Genre</div>
          {genreData && showCharts && <GenreChart genreData={genreData} />}
        </div>
        <div className="achievement-yearly">
          <div className="subtitle">Yearly Achievement Activity</div>

          {achiYearCount && showCharts && (
            <AchiActivityChart yearDataArray={achiYearCount} />
          )}
        </div>
      </div>

      <Heading marginTop={48} marginBottom={16}>
        Achievements Record
      </Heading>
      <div style={{ width: "95%" }}>
        {showCharts && filteredAchievements && (
          <BubbleChart achievements={filteredAchievements} />
        )}
      </div>
      <Heading marginTop={48} marginBottom={16}>
        Your Achievement Rarities Through Time
      </Heading>
      <div>
        {showCharts &&
          filteredAchievements &&
          filteredAchievements.length &&
          `From ${filteredAchievements[0].unlockDate} to ${
            filteredAchievements[filteredAchievements.length - 1].unlockDate
          }`}
      </div>
      {showCharts && filteredAchievements && (
        <div style={{ width: "95%" }}>
          <ScatterChart achievements={filteredAchievements} flexAchiDict={flexAchiDict}/>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
