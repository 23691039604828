import React, { useEffect, useState } from "react";
import get from 'lodash/get'
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Progress,
  Icon,
  useDisclosure,
  Input,
  Button,
  Select,
  Checkbox,
  Stack,
  Form
} from "@chakra-ui/react";
import { MdClose, MdDownloadDone } from "react-icons/md";

import { getGameAchievements, getGlobalAchievements } from "../../../api";
import UploadProgress from '../UploadProgress'

GameAchievementModal.propTypes = {};

function GameAchievementModal(props) {
  const { isOpen, gameAppId = '' } = props;
  const [gameData, setGameData] = useState(
    null
  );
  const [achievements, setAchievements] = useState([]);
  const [selectedAchievements, setSelectedAchievements] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [appid, setAppid] = useState(gameAppId)
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    if (loading) return;
    props.onClose();
  };

  const getGameData = async () => {
    setLoading(true);
    try {
      const globalAchievementResponse = await getGlobalAchievements(appid)
      const rarities = globalAchievementResponse.data.achievements.reduce((acc, achi) => {
        acc[achi.name] = achi.percent;
        return acc
      }, {})
      const response = await getGameAchievements(appid);
      setGameData(response.data);
      const achievementArrayLength = get(
        response.data,
        "availableGameStats.achievements.length",
        0
      );
      const mappedWithRarity = get(
        response.data,
        "availableGameStats.achievements",
        []
      ).map((achi) => {
        const rarity = rarities[achi.name] || 0;
        return { ...achi, rarity };
      }).sort((a, b) => a.rarity - b.rarity);
      setAchievements(mappedWithRarity);
      setCheckedItems(Array(achievementArrayLength).fill(false));
    } catch (error) {
      alert(`something went wrong. ${JSON.stringify(error)}`);
      console.error("error while gettting game data", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const renderAppAchievements = () => {
    return (
      <>
        <ModalHeader>
          {gameData ? `${gameData.gameName} Achievements` : "Game Achievements"}
        </ModalHeader>
        <label style={{ marginBottom: "4px" }}>Insert APPID here:</label>
        <Input
          value={appid}
          marginBottom="16px"
          onChange={(event) => setAppid(event.target.value)}
        ></Input>
        <Button
          onClick={getGameData}
          ml={3}
          colorScheme="green"
          isLoading={loading}
          marginBottom="16px"
        >
          Get Achievements
        </Button>

        { get(gameData, "availableGameStats.achievements.length") && (<><div cl>Select {gameData.gameName}'s Achievements to Add:</div>
        <Stack
          direction="column"
          marginTop="8px"
          border="1px solid gray"
          padding="8px"
          maxHeight={400}
          overflowY="scroll"
        >
          {achievements.map(
            (achievement, index) => {
              return (
                <Checkbox
                  onChange={(e) => {
                    e.stopPropagation();
                    checkedItems[index] = e.target.checked;
                    setCheckedItems(checkedItems);
                  }}
                  key={index}
                  borderBottom="0.5px solid gray"
                  paddingBottom="16px"
                  paddingTop="16px"
                >
                  {achievement.displayName}[{achievement.name}]-{" "}
                  <span style={{color: 'green', fontWeight: 'bold'}}>
                    {achievement.rarity ? achievement.rarity.toFixed(2) : "_"}%
                  </span>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "gray",
                    }}
                  >
                    <span>{achievement.description}</span>
                  </div>
                </Checkbox>
              );
            }
          )}
        </Stack>

        <ModalFooter marginTop="20px">
          <Button
            onClick={onAddAchievements}
            ml={3}
            colorScheme="green"
            isLoading={loading}
          >
            Save
          </Button>
        </ModalFooter>
        </>)}
      </>
    );
  }


  const onAddAchievements = () => {
    const achievementToAdd = [];

    checkedItems.forEach((bool, index) => {
      if (bool) {
        achievementToAdd.push(achievements[index]);
      }
    });
    console.log("achievementToAdd", achievementToAdd);
    setSelectedAchievements(achievementToAdd);
  };

   return (
     <Modal isOpen={isOpen} onClose={onClose}>
       <ModalOverlay />
       <ModalContent maxWidth="600px" padding="40px 20px">
         {selectedAchievements.length
         ? <UploadProgress
            onClose={onClose}
            achievements={selectedAchievements}
            appid={appid}
            gameName={gameData.gameName}/>
         : renderAppAchievements()}
       </ModalContent>
     </Modal>
   );
}

export default GameAchievementModal;
