import React from "react";
import {
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  useUser,
  UserButton
} from "@clerk/clerk-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LogRocket from "logrocket";


// pages
import Recipes from "./pages/Recipes/";
import Achievements from './pages/Achievements'
import Games from './pages/Games'
import CommonGamesPage from "./pages/CommonGames";
import UsersPage from "./pages/Users";
import UserProfile from "./pages/UserProfile"

// components
import Private from "./components/Private";
import AdminHeader from './components/AdminHeader'


import { doesHaveRole } from "./services/auth";

// create a function to check if user running on localhost or not
const isLocalhost =
  window.location.href.includes("localhost") ||
  window.location.href.includes("127.0.0.1");


if(!isLocalhost) {
 console.log('log rocket is running')
 LogRocket.init("0ux8n8/flex");
}

export default function App() {
  const { user } = useUser();

  const renderAdminNav = () => {
    if (!window.location.href.includes("admin")) {
      return null
    }
      if (doesHaveRole(user, "admin")) {
        return (
          <div>
            <AdminHeader />
            <div style={{ position: "absolute", top: "4px", right: "12px" }}>
              <UserButton />
            </div>
          </div>
        );
      }

    if(user){
      return (
        <div style={{ margin: "20px" }}>
          Forbidden, please contact administration.
          <div style={{ position: "absolute", top: "4px", right: "12px" }}>
            <UserButton />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <SignedIn>
      <Private role="admin">
        {renderAdminNav()}
        <Routes base>
          <Route path="/admin/recipes" element={<Recipes />} />
          {/* <Route path="/admin/achievements" element={<Achievements />} /> */}
          <Route path="/admin/games" element={<Games />} />
          <Route path="/admin/common-games" element={<CommonGamesPage />} />
          <Route path="/admin/users" element={<UsersPage />} />
        </Routes>
        </Private>
      </SignedIn>

      {/* public pages */}
      <Routes>
        <Route path="/login" element={<RedirectToSignIn />} />
        <Route path="/user" element={<UserProfile />} />
      </Routes>
      </>
  );
}
