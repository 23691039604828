import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from "react-select";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";

import './styles.scss'

Filters.propTypes = {

};

function Filters(props) {
    const { onFilterChange, filters, yearRange, gamesData } = props;
    const [selectedGames, setSelectedGames] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if(gamesData && gamesData.games && gamesData.games.length) {
        const sortedGames = gamesData.games.sort(
          (a, b) => b.playTimeInHours - a.playTimeInHours
        );
        const mappedOptions = sortedGames.map((game) => ({
          label: `${game.gameName} (${game.playTimeInHours ? game.playTimeInHours.toFixed(2,10) : ''} hours)`,
          value: game.appid,
        }));
        setOptions(mappedOptions);
      }
    }, [gamesData]);

    const onSliderValueChange = (valueArray) => {
      // console.log("value", value);
      onFilterChange({
        percent: {
          min: valueArray[0],
          max: valueArray[1],
        },
      });

    };

    const onYearValueChange = (valueArray) => {
      onFilterChange({
        year: {
          min: valueArray[0],
          max: valueArray[1],
        },
      });

    };

    const onHourValueChange = (hours) => {
      onFilterChange({
        gameHours: hours,
      });
    };

    const onGamesFilterChange = (games) => {
      if(games.length === 0) {
        onFilterChange({
          games: undefined,
        });
      } else {
        const gamesDict = games.reduce((sum, game) => {
          sum[game.value] = true;
          return sum;
        }, {});
        setSelectedGames(games);
        onFilterChange({
          games: gamesDict,
        });
      }
    };

  return (
    <div className="filters-container">
      <div className="achi-filter">
        <div className="filter-label">Set Achievement Percent</div>
        <RangeSlider
          aria-label={["min", "max"]}
          defaultValue={[0, 100]}
          min={0}
          max={100}
          onChangeEnd={onSliderValueChange}
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
        {filters.percent.min}% - {filters.percent.max}%
      </div>
      {yearRange && (
        <div className="achi-filter">
          <div className="filter-label">Set Year Range</div>
          <RangeSlider
            aria-label={["min", "max"]}
            min={yearRange[0]}
            max={yearRange[1]}
            step={1}
            defaultValue={[filters.year.min, filters.year.max]}
            onChangeEnd={onYearValueChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
          {filters.year.min} - {filters.year.max}
        </div>
      )}
      {
        <div className="achi-filter">
          <div className="filter-label">By Game Hourly</div>
          <Slider
            aria-label="slider-ex-1"
            min={0}
            max={500}
            defaultValue={filters.gameHours}
            onChangeEnd={onHourValueChange}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          {`>= ${filters.gameHours}+ hours`}
        </div>
      }
      <div className="achi-filter">
        <div className="filter-label">By Game</div>
        <ReactSelect
          className="game-select"
          defaultValue={selectedGames}
          onChange={onGamesFilterChange}
          options={options}
          isMulti={true}
        />
      </div>
    </div>
  );
}

export default Filters;
