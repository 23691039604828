import React from 'react';
import PropTypes from 'prop-types';
import { Line } from "react-chartjs-2";

AchiActivityChart.propTypes = {

};

function getRandomRgba() {
  const x = Math.floor(Math.random() * 256);
  const y = 100 + Math.floor(Math.random() * 256);
  const z = 50 + Math.floor(Math.random() * 256);
  const bgColor = "rgb(" + x + "," + y + "," + z + ")";
  return bgColor;
}

const yearColors = {}
const getYearColor = (year) => {
  if (yearColors[year]) {
    return yearColors[year];
  } else {
    yearColors[year] = getRandomRgba();
    return yearColors[year];
  }
};


function AchiActivityChart(props) {
  const { yearDataArray } = props;
  const labels = yearDataArray.map((item) => item.year);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Total Achievements",
        data: yearDataArray.map((yearData) => yearData.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderWidth: 4,
      },
    ],
  };
  return <Line options={options} data={data} />;
}

export default AchiActivityChart;
