import React, { useEffect, useState } from "react";
import { Spinner, Center } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import _ from 'lodash'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Input,
} from "@chakra-ui/react";

import { getUsers } from "../../api/index";
import UserFlex from "../../components/UserFlex";

// import AchievementLabel from "../../components/Recipe/AchievementLabel";
// import RecipeModal from "../../components/Recipe/RecipeModal";

const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [steamId, setSteamId] = useState(null)
  const [pageSize, setPageSize] = React.useState(20);
  const [page, setPage] = React.useState(0);

  const [size, setSize] = useState(40);

  const getUsersData = async () => {
    setLoading(true);
    const opts = { page, pageSize, gameId: steamId };
    const response = await getUsers(opts);
    if (response) {
      setData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsersData();
  }, [page]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPage(event.selected)
  };

   const onEnter = (event) => {
     if (event.key === "Enter") {
       getUsersData();
     }
   };


  const onSearchValueChange = (event) => {
   setSteamId(event.target.value)
  };

  const renderSearchBox = () => {
    return (
      <div style={{marginBottom: '8px'}}>
        <Input
          placeholder="Search By SteamId"
          value={steamId}
          marginRight="8px"
          width="300px"
          onChange={onSearchValueChange}
          onKeyDown={onEnter}
        />
        <Button colorScheme={"blue"} onClick={getUsersData}>
          Search
        </Button>
      </div>
    );
  }

  return (
    <>
      {data.users && (
        <div className="user-container" style={{ padding: "80px 64px" }}>
          {renderSearchBox()}
          <TableContainer>
            <div>Total: {data.total}</div>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>sn</Th>
                  <Th>steamId</Th>
                  <Th>ProcessedDate</Th>
                  <Th>Notes</Th>
                  <Th>State</Th>
                  <Th>Total games</Th>
                  <Th>Flexes</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.users.map((user, index) => {
                  return (
                    <Tr
                      key={index}
                      // onClick={() => onSelectRow(user)}
                      cursor="pointer"
                      _hover={{ backgroundColor: "lightblue" }}
                      backgroundColor={index%2 === 0 ? '#f9f9f9' : ''}
                    >
                      <Td>{index + page * pageSize + 1}</Td>
                      <Td>
                        <div>{user.gameId}</div>
                        <div>
                        {
                          _.get(user, 'info.realname') ? `real name: ${_.get(user, 'info.realname')}` : `persona name: ${_.get(user, 'info.personaname')}`
                        }
                        </div>
                      </Td>
                      <Td>
                        {" "}
                        {user.processedDate
                          ? moment(user.processedDate).format(
                              "DD/MM/YYYY hh:mm a"
                            )
                          : ""}
                      </Td>
                      <Td>{user.notes}</Td>
                      <Td>{user.state}</Td>
                      <Td>{user.totalGames}</Td>
                      <Td>
                        {user.totalGames ?  <UserFlex userId={user.gameId} /> : ''}
                      </Td>
                      {/* <Td>
                        <Button colorScheme="green">Add Achievements</Button>
                      </Td> */}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <div
            // style={{
            //   display: "flex",
            // }}
            >
              <ReactPaginate
                className="pagination"
                // breakLabel="..."
                initialPage={0}
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={data.total ? data.total / pageSize : 0}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            </div>
            {/* <Button margin="20px" onClick={() => setSize(size + 40)}>
              Load More...
            </Button> */}
          </TableContainer>
        </div>
      )}
      {loading && (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            margin="auto"
            marginTop={'200px'}
            alignContent={"center"}
          />
        </Center>
      )}
    </>
  );
};

export default UsersPage;
