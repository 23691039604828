import React from 'react';
import PropTypes from 'prop-types';
import { doesHaveRole } from "../../services/auth";
// import { Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

Index.propTypes = {

};

function Index({children, role, redirectionPath = '/'}) {
  const { user } = useUser();
  if(doesHaveRole(user, role)) {
     return <>{children}</>
  }
}

export default Index;
